import React, { useEffect, useState } from "react"

import { graphql, useStaticQuery, navigate } from "gatsby"
import { useLocation } from "@reach/router"

import Layout from "../../components/layout"
import { Footer } from "../../components/footer"
import SEO from "../../components/seo"
import { maxWidth } from "../../components/maxwidth"

import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ButtonBase from "@material-ui/core/ButtonBase"
import Grid from "@material-ui/core/Grid"
import Dialog from "@material-ui/core/Dialog"
import { makeStyles } from "@material-ui/core/styles"

import { GatsbyImage } from "gatsby-plugin-image"
import { colors } from "../../components/colors"
import { ContactForm } from "../../components/contactForm"

const useStyles = makeStyles(theme => ({
  banner: {
    "& img, .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },

    "& .overlay": {
      backgroundColor: "rgba(36,120,153,0.65)",
      mixBlenderMode: "multiply",
    },

    "& .bannerText": {
      marginTop: "90px",
      color: "#fff",
      textAlign: "center",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "1rem",
      },
    },
  },
  catContainer: {
    width: "100%",
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexWrap: "wrap",

    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },

    "& .categories": {
      width: "100%",
      maxWidth,
      padding: "50px 0px",

      "& .container": {
        width: "100%",
        padding: "0 5%",

        [theme.breakpoints.up("sm")]: {
          padding: "0 15%",
        },

        "& .description": {
          margin: "15px 0",
          color: colors.grayText,
          textAlign: "justify",
        },
      },
    },
  },
}))

const pages = ["assessoria", "civil", "tributario", "contratos"]

const Atuacao = () => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner_quem_somos.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      site {
        siteMetadata {
          atuacao {
            id
            text
            title
            ul
          }
        }
      }
    }
  `)

  const {
    site: {
      siteMetadata: { atuacao },
    },
  } = data

  const classes = useStyles()

  const location = useLocation()

  const [current, setCurrent] = useState(null)

  useEffect(() => {
    const path = location.pathname.replace("/atuacao/", "")

    if (pages.includes(path)) {
      setCurrent(atuacao.filter(item => item.id === path)[0])
    } else {
      navigate("/404")
    }
  }, [location.pathname])

  return (
    <Layout>
      <SEO
        title={`Atuação | ${current?.title}`}
        description={current?.text[0]}
      />
      {current && (
        <Box
          width={1}
          height="100vh"
          display="flex"
          flexDirection="column"
          style={{ overflowY: "auto" }}
        >
          <Box
            width={1}
            display="flex"
            justifyContent="center"
            height={{ xs: "320px" }}
            mt={{ xs: 0 }}
            className={classes.banner}
            position="relative"
          >
            <GatsbyImage
              image={data.banner.childImageSharp.gatsbyImageData}
              alt="banner home"
            />
            <Box
              width={1}
              height={1}
              position="absolute"
              className="overlay"
            ></Box>
            <Box
              width={1}
              maxWidth={maxWidth}
              position="absolute"
              display="flex"
              flexWrap="wrap"
              height="100%"
              alignItems="center"
              px={{ xs: "10px", sm: "20px" }}
            >
              <Box width={1} p={{ xs: "30px", sm: "50px" }}>
                <Typography variant="h4" className="bannerText">
                  {current.title.toUpperCase()}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            width={1}
            display="flex"
            justifyContent="center"
            height={{ xs: "auto", sm: 1 }}
          >
            <Box className={`${classes.catContainer}`}>
              <Box className="categories">
                <Box className="container">
                  {current.text.map((tx, txid) => (
                    <Typography
                      key={`text-${txid}`}
                      variant="body1"
                      className="description"
                    >
                      {tx}
                    </Typography>
                  ))}
                  {current.ul && (
                    <ul>
                      {current.ul.map((li, lindex) => (
                        <Typography
                          key={`li-${lindex}`}
                          variant="body1"
                          component="li"
                        >
                          {li}
                        </Typography>
                      ))}
                    </ul>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            width={1}
            maxWidth={maxWidth}
            mx="auto"
            display="flex"
            flexDirection="column"
            alignItems="center"
            px={{ xs: "10px", sm: "20px" }}
          >
            <Box
              display="flex"
              width={1}
              my="50px"
              color={colors.azul}
              justifyContent="center"
            >
              <Typography variant="h4" align="center">
                RECEBA UM CONTATO ESPECIALIZADO
              </Typography>
            </Box>
            <Box width={{ xs: 1, sm: 0.7 }} mb="50px">
              <ContactForm />
            </Box>
          </Box>
          <Footer />
        </Box>
      )}
    </Layout>
  )
}

export default Atuacao
